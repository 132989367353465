<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">

            <v-col
                cols="12"
                class="py-2"
                >
                <v-btn-toggle
                    v-model="type"
                    tile
                    color="primary"
                    group
                >
                    <v-btn value="suspend">
                    File Reactivation
                    </v-btn>

                    <v-btn value="approve">
                    Approve Reactivation
                    </v-btn>
                </v-btn-toggle>
            </v-col>

            <v-row class="ma-3" v-if="type == 'suspend'">
                <v-col :cols="12">
                    <v-select
                        class="mx-5" style="float:right"
                        :items="items"
                        v-model="defaultItem"
                        label="Deactivation Scope"
                        dense
                        outlined
                    ></v-select>
                </v-col>

                <v-col cols="12" v-if="defaultItem == 'Entity'">

                    <v-col>
                        <h3 class="mx-5">Reactivation Per Entity</h3>
                    </v-col>

                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="entityHeaders"
                            :items="entity"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.sp_primary_details.account_name`]="{item}">
                                <div style="cursor: pointer;" @click="$router.push({ path: 'sales-prospecting/view-profile/' + item.id })">
                                    {{ item.sp_primary_details.account_name }}
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <v-btn
                                    v-if="initialStatus.includes(item.status)"
                                    text
                                    color="primary"
                                    @click="$router.push({ path:'/sales-prospecting/initial-view-only/'+item.id, query: { viewOnly: true } }) "
                                    >
                                    <v-icon>mdi-eye-outline</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="finalStatus.includes(item.status)"
                                    text
                                    color="primary"
                                    @click="$router.push({ path:'/sales-prospecting/final-view-only/'+item.id, query: { viewOnly: true } }) "
                                    >
                                    <v-icon>mdi-eye-outline</v-icon>
                                </v-btn>
                                <v-btn
                                    text
                                    color="success"
                                    @click="dialog = true; itemId = item.id"
                                    >
                                    <v-icon>mdi-file-send-outline</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="ma-3" v-if="defaultItem == 'Location' && type == 'suspend'">

                <v-col>
                    <h3 class="mx-5">Reactivation Per Location</h3>
                </v-col>


                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="locationHeaders"
                            :items="locations"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.sp_primary_details.account_name`]="{item}">
                                <div style="cursor: pointer;" @click="$router.push({ path: 'sales-prospecting/view-profile/' + item.id })">
                                    {{ item.sp_primary_details.account_name }}
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <v-btn
                                    v-if="initialStatus.includes(item.status)"
                                    text
                                    color="primary"
                                    @click="$router.push({ path:'/sales-prospecting/initial-view-only/'+item.id, query: { viewOnly: true } }) "
                                    >
                                    <v-icon>mdi-eye-outline</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="finalStatus.includes(item.status)"
                                    text
                                    color="primary"
                                    @click="$router.push({ path:'/sales-prospecting/final-view-only/'+item.id, query: { viewOnly: true } }) "
                                    >
                                    <v-icon>mdi-eye-outline</v-icon>
                                </v-btn>
                                <v-btn
                                    text
                                    color="success"
                                    @click="dialog = true; itemId = item.id"
                                    >
                                    <v-icon>mdi-file-send-outline</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            
            <v-dialog
            v-model="dialog"
            width="900px"
            persistent
            >
                <v-card>
                    <v-card-title>
                    <span class="text-h5" id="dialog_title">File Reactivation</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <h4>Supporting Document</h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-file-input
                                    v-model="supportingDoc.reactivation_documents"
                                    label="Upload Supporting Documents"
                                    multiple
                                    dense
                                    outlined
                                    prepend-icon="mdi-paperclip"	
                                    >
                                    <template v-slot:selection="{ text, index }">
                                        <v-chip
                                            close
                                            small
                                            @click:close="removeFile(index, 'reactivation_documents')"
                                            >
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                            </v-row>
                            
                            <v-row>
                                <v-col>
                                    <h4>Reason for Reactivation</h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                <v-textarea
                                    label="Remarks"
                                    auto-grow
                                    outlined
                                    v-model="supportingDoc.reactivation_remarks"
                                ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined color="primary"
                        text
                        @click="dialog = false; clearDialog()"
                    >
                        Cancel
                    </v-btn>
                    <v-btn color="primary" @click="fileReactivation"
                        v-if="supportingDoc.reactivation_remarks && supportingDoc.reactivation_documents"
                    >
                        File Reactivation
                    </v-btn>
                    <v-btn color="primary"
                        v-else
                        disabled
                    >
                        File Reactivation
                    </v-btn>
                    </v-card-actions>
            </v-card>
            </v-dialog>

            <ReactivationApprovalOnboarding v-if="type == 'approve'"> </ReactivationApprovalOnboarding>


        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'
import _ from 'lodash';
import jsonToFormData from 'json-form-data';
import ReactivationApprovalOnboarding from './ReactivationApprovalOnboarding.vue';

export default {
  name: 'Users',
  data () {
    return {
        search: '',
        initialStatus: ['pending','Scorecard Failed','Inital Evaluation Committee Approval Pending','Inital Evaluation Committee Approval Pending','Initial Evaluation Committee Approved','existing','Not accepted','Pending (Presentation)','For negotiation','Accepts Partnership','Evaluation (Returned)','Archived','Evaluation (Hanging)','Initial Evaluation Committee Approval Pending'],
        finalStatus: ['Initial Evaluation Complete','Onboarding Approval Pending','Onboarding Approved','Initial Existing Complete','Existing Complete','Existing (Validation Approval Pending)','Onboarding (Returned)','Validation (Returned)','Onboarding (Hanging)','Merged (IS)','Existing Complete (IS)'],
        entity: [],
        entityHeaders: [
            {
                text: 'Account Name',
                sortable: true,
                value: 'sp_primary_details.account_name'
            },
            {
                text: 'BP ID Number',
                sortable: true,
                value: 'bp_idno'
            },
            {
                text: 'SP Number',
                sortable: true,
                value: 'prospective_number'
            },
            {
                text: 'Owner',
                sortable: true,
                value: 'created_by'
            },
            {
                text: 'Status',
                sortable: true,
                value: 'account_status'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
        locations: [],
        locationHeaders: [
            {
                text: 'Branch Name',
                sortable: true,
                value: 'branch_name'
            },
            {
                text: 'Branch ID',
                sortable: true,
                value: 'id'
            },
            {
                text: 'Location Code',
                sortable: true,
                value: 'location_code'
            },
            {
                text: 'SP Number',
                sortable: true,
                value: 'sales_prospects_id'
            },
            {
                text: 'Status',
                sortable: true,
                value: 'account_status'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
        defaultItem: 'Entity',
        items: ['Entity','Location'],
        dialog: false,
        itemId: '',
        supportingDoc: {
            reactivation_remarks: '',
            reactivation_documents: null
        },
        type: 'suspend'
    }
  },
    components:{
        ReactivationApprovalOnboarding: ReactivationApprovalOnboarding
    },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  async mounted () {
    this.assignData()
  },
  methods: {
    removeFile (index, property) {
        this.supportingDoc[property].splice(index, 1)
    },
    async assignData(){
        this.entity = []
        this.locations = []
        
        const res = await this.$store.dispatch('salesProspecting/doGetReactivationList')

        res.data.result.entity.map(e => {
            if (e.account_status == 'Suspended'){
                this.entity.push(e)
            }
        })

        res.data.result.location.map(e => {
            if (e.account_status == 'Suspended'){
                this.locations.push(e)
            }
        })
    },
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
    },
    async fileReactivation () {
        var options = {
            initialFormData: new FormData(),
            howLeafArrayIndexes: true,
            includeNullValues: false,
            mapping: function(value) {
                if (typeof value === 'boolean') {
                    return +value ? '1': '0';
                }
                return value;
            }
        };
        let finalPayload =  _.cloneDeep(this.supportingDoc);
        var convertedFormData = jsonToFormData(finalPayload, options)
        const payload = {id: this.itemId, payload: convertedFormData}

        if (this.defaultItem == 'Entity'){
            const res = await this.$store.dispatch('salesProspecting/doFileReactivationEntity', {payload})
            if (res.status == 200){
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Reactivation Filing Successful!' ,'messagetype':'success'},{ root: true });
                this.dialog = false
                this.clearDialog()
                this.assignData()
            } else {
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Something Went Wrong!' ,'messagetype':'error'},{ root: true });
            }
        } else {
            const res = await this.$store.dispatch('salesProspecting/doFileReactivationLocation', {payload})
            if (res.status == 200){
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Reactivation Filing Successful!' ,'messagetype':'success'},{ root: true });
                this.dialog = false
                this.clearDialog()
                this.assignData()
            } else {
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Something Went Wrong!' ,'messagetype':'error'},{ root: true });
            }
        }
    },
    clearDialog(){
        this.itemId = '',
        this.supportingDoc.reactivation_remarks = '',
        this.supportingDoc.reactivation_documents = null
    }
  }
}
</script>
